<template>
  <main>
    <sqr-error :error="loadError" />
    <sqr-progress v-show="loading" />

    <!-- <pre>{{records}}</pre> -->

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>{{ $t('trail_timestamp') }}</th>
          <th>{{ $t('trail_user') }}</th>
          <th>{{ $t('trail_account') }}</th>
          <th>{{ $t('trail_record') }}</th>
          <th>{{ $t('trail_id') }}</th>
          <th>{{ $t('trail_op') }}</th>
          <th>{{ $t('trail_actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="rec in records" :key="rec.id">
          <td :title="rec.tid">{{ rec.timestamp | timestamp }}</td>
          <td>{{ rec | by }}</td>
          <td>{{ rec.aid }}</td>
          <td>{{ rec.col }}</td>
          <td>
            <template v-if="rec.col === 'sheets'">
              <router-link :to="recTo(rec)">
                {{ rec.id }}
              </router-link>
            </template>
          </td>
          <td>
            {{ rec | op }}
          </td>
          <td>
            <a @click="restore(rec)">
              {{ $t('trail_restore') }}
            </a>
            <sqr-progress v-show="restoring[rec.tid]" />
          </td>
        </tr>
      </tbody>
    </table>

    <section class="px-4 mt-2 pt-2 border-t bg-white sticky bottom-0">
      <div class="">
        <sqr-fire-page
          v-bind="pagination"
          @page-set="pageSet"
          @page-size="pageSize"
        />
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

import company from './company';

import timestamp from '@/filters/timestamp';

import SqrError from '@/sqrd/SqrError';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrFirePage from '@/sqrd/SqrFirePage';

import buildRef from '@/utils/buildRef';
import stamp from '@/utils/stamp';

export default {
  name: 'CompanyAuditTrail',
  mixins: [company],
  filters: {
    timestamp,
    op(rec) {
      if (!rec) {
        return '?';
      } else if (rec.before && rec.after) {
        return 'update';
      } else if (rec.before) {
        return 'delete';
      } else if (rec.after) {
        return 'create';
      } else {
        return 'N/A';
      }
    },
    by(rec) {
      const doc = rec.after ?? rec.before;
      return doc?.updated?.displayName;
    },
  },
  components: { SqrError, SqrProgress, SqrFirePage },
  computed: {
    ...mapState('trail', ['records', 'loading', 'loadError']),
    ...mapGetters('trail', ['pagination']),
  },
  data() {
    return {
      restoring: {},
    };
  },
  mounted() {
    this.sub({
      path: ['companies', this.cid, 'trail'],
      orderBy: [['timestamp', 'desc']],
      select: ['timestamp', 'displayName', 'uid', 'email', 'col', 'id'],
    });
  },
  methods: {
    ...mapActions('trail', ['sub', 'pageSet', 'pageSize']),
    recTo(rec) {
      const cid = rec.cid;
      const aid = rec.aid;
      switch (rec.col) {
        case 'sheets':
          return { name: 'sheet', params: { cid, aid, sid: rec.id } };
        case 'events':
          return { name: 'event', params: { cid, aid, eid: rec.id } };
        case 'rights':
          return { name: 'right', params: { cid, aid, rid: rec.id } };
        case 'accounts':
          return { name: 'account', params: { cid, aid } };
        case 'companies':
          return { name: 'company', params: { cid } };
      }
      if (aid) {
        return { name: 'account', params: { cid, aid } };
      } else if (cid) {
        return { name: 'company', params: { cid } };
      } else {
        return {};
      }
    },
    async restore(rec) {
      try {
        this.$set(this.restoring, rec.tid, true);
        const path = [
          'companies',
          rec.cid,
          'accounts',
          rec.aid,
          rec.col,
          rec.id,
        ];
        const ref = buildRef(path);
        const payload = {
          ...rec.before,
          updated: stamp(),
        };
        await ref.set(payload);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.$delete(this.restoring, rec.tid);
      }
    },
  },
};
</script>
